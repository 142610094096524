<template>
  <div class="pageContol listWrap templateList promoteBox">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">视频推荐</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="推荐名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">推荐名称:</span>
              <el-input
                v-model="recommendName"
                type="text"
                size="small"
                placeholder="请输入推荐名称"
                clearable
              />
            </div>
            <div title="行政区域" class="searchboxItem ci-full">
              <span class="itemLabel">行政区域:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                placeholder="请选择行政区划"
                size="small"
              ></el-cascader>
            </div>
            <div title="推荐系统" class="searchboxItem ci-full terminalsSet">
              <span class="itemLabel">推荐系统:</span>
              <el-cascader
                :options="PublishingSystemList"
                :props="{ multiple: true,emitPath:false }"
                placeholder="请选择推荐系统"
                clearable
                v-model="terminals"
                size="small"
              ></el-cascader>
            </div>
            <div class="btnBox">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="add('add')"
                >新增视频推荐</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addCourse('add')"
                >新增课程推荐</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :span-method="objectSpanMethod"
              :header-cell-style="tableHeader"
              :cell-style="cellbgc"
              @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
              @sort-change="changeSort"
              :default-sort="{ prop: 'createTime', order: 'descending' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="推荐名称"
                align="center"
                prop="recommendName"
                show-overflow-tooltip
                min-width="260"
                fixed
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.recommendName }}</span>
                </template>
              </el-table-column>
  <el-table-column
                label="排序"
                align="center"
                prop="sort"
                sortable="custom"
                show-overflow-tooltip
                min-width="150"
                fixed
              >
                <template slot-scope="scope">
                  <div v-if="!scope.row.orderShow">
                    <span> {{ scope.row.sort || 0 }}</span>
                    <i
                      class="el-icon-edit"
                      v-show="scope.row.showInput"
                      @click="changeOrder(scope.row)"
                    ></i>
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.sort"
                    maxlength="1000"
                    size="small"
                    @blur="
                      () => {
                        editRowSort(scope.row);
                      }
                    "
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="120"
              />
              <el-table-column
                label="岗位类型"
                align="left"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.postName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="行业类型"
                align="left"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.industryNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="left"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.occupationNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="资源提供方"
                align="center"
                show-overflow-tooltip
                prop="compNamePath"
                min-width="260"
              >
                <template slot-scope="scope">
                  {{ scope.row.compNamePath || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="推荐行政区划"
                align="center"
                show-overflow-tooltip
                prop="areaNamePath"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.areaNamePath || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="推荐系统"
                align="center"
                show-overflow-tooltip
                prop="terminal"
              >
                <template slot-scope="scope">
                  {{ scope.row.terminal }}
                </template>
              </el-table-column>
              <el-table-column
                label="推荐位置"
                align="center"
                min-width="150"
                show-overflow-tooltip
                prop="recommendSiteNamePath"
              />

              <el-table-column
                label="推荐状态"
                align="center"
                show-overflow-tooltip
                prop="enable"
              >
                <template slot-scope="scope">
                  {{ scope.row.enable ? "启用" : "禁用" }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="center"
                show-overflow-tooltip
                :sort-by="'createTime'"
                :sort-orders="['ascending', 'descending', null]"
                prop="createTime"
                width="150"
                sortable="custom"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 15px"
                    size="mini"
                    v-if="scope.row.recommendType == '10'"
                    @click="
                      handleLook(
                        scope.row.kpointName,
                        scope.row.kpointVideoId,
                        scope.row.kpointId
                      )
                    "
                    >预览</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 15px"
                    size="mini"
                    v-else
                    @click="
                      handleLookCourse(
                        'look',
                        scope.row.recommendId,
                        scope.row.courseId
                      )
                    "
                    >预览</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 15px"
                    size="mini"
                    @click="
                      handleEdit(
                        'edit',
                        scope.row.recommendId,
                        scope.row.recommendType,
                        scope.row.courseId
                      )
                    "
                    >推荐设置</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 15px"
                    size="mini"
                    @click="handleDelete(scope.row.recommendId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/videolist",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      areaId: "", // 行政区划
      terminals: [],
      areatreeList: [],
      propsarea: {
        //行政规划的值
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      bannerStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "10",
          label: "启用",
        },
        {
          value: "20",
          label: "禁用",
        },
      ],
      PublishingSystemList: [
        {
          value: 1,
          label: "APP",
        },
        {
          value: 2,
          label: "安知培训",
        },
        {
          value: 4,
          label: "安知实习",
        },
      ],
      state: "", // 轮播图状态
      recommendName: "", //推荐视频名称
      sort: "", //排序
      createTimeSort: "2", //排序
    };
  },
  computed: {},
  created() {
    this.getareatree();
  },
  methods: {
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      if (column.label == "排序") {
        row.showInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      row.orderShow = !row.orderShow;
    },
    //输入框失去焦点 保存修改
    editRowSort(row) {
      console.log(row.sort);
      // console.log(row.)
      let url = "/sys/recommend/changeOrder";
      this.formatValue(row.sort);
      const params = {
        sort: this.value,
        recommendId: row.recommendId,
      };
      this.$post(url, params).then((res) => {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getData(-1);
      });
    },
    formatValue(val) {
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.value = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.value = val;
    },
    getData(pageNum = 1) {
      // console.log(this.state);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.recommendName) {
        params.recommendName = this.recommendName;
      }
      if (this.terminals) {
        params.terminal = this.terminals;
      }
      if (this.sort) {
        params.sort = this.sort;
      }
      if (this.createTimeSort) {
        params.createTimeSort = this.createTimeSort;
      }
      this.doFetch({
        url: "/sys/recommend/page",
        params,
        pageNum,
      });
    },
    handleListDataBack(data) {
      this.tableData = [];

      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        // let terminal = [];
        // let arr = el.terminal.split(",");
        // arr.map((e) => {
        //   terminal.push(this.$setDictionary("TERMINAL", e));
        // });
        // el.terminal = terminal.toString();
        this.tableData.push(el);
      });
    },
    changeSort(row, clounm) {
      if (row.prop == "sort") {
        this.sort = row.order == "ascending" ? "1" : "2";
        this.createTimeSort = '';
      }
      if (row.prop == "createTime") {
        this.createTimeSort = row.order == "ascending" ? "1" : "2";
        this.sort = '';
      }

      this.getData();
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        const list = [{ value: "-10000", label: "全部区域" }];
        for (const key in ret.data) {
          list.push({
            value: ret.data[key].value,
            label: ret.data[key].label,
            children: ret.data[key].children,
          });
        }
        this.areatreeList = list;
      });
    },
    // 删除轮播图
    handleDelete(recommendId) {
      this.doDel({
        url: "/sys/recommend/delete",
        msg: "是否确认删除？",
        ps: {
          type: "post",
          data: { recommendId },
        },
      });
    },
    // 新增推广
    add(stu) {
      this.$router.push({
        path: "/web/operate/videoedit",
        query: { stu, id: null },
      });
    },
    // 新增推广
    addCourse(stu) {
      this.$router.push({
        path: "/web/operate/videoCourseEdit",
        query: { stu, id: null },
      });
    },
    /* 预览 */
    handleLook(kpointName, kpointVideoId, kpointId) {
      this.$store.dispatch("handleVideoUrl", {
        kpointVideoId: kpointVideoId,
        kpointName: kpointName,
        kpointId: kpointId,
      });
    },
    handleLookCourse(stu, id, courseId) {
      this.$router.push({
        path: "/web/operate/videoCourseEdit",
        query: { stu, id, courseId },
      });
    },
    handleEdit(stu, id, recommendType, courseId) {
      if (recommendType == "20") {
        this.$router.push({
          path: "/web/operate/videoCourseEdit",
          query: { stu, id, courseId },
        });
      } else {
        this.$router.push({
          path: "/web/operate/videoedit",
          query: { stu, id },
        });
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.promoteBox {
  .terminalsSet {
    /deep/.el-cascader {
      width: 215px;
      .el-cascader__tags {
        .el-tag {
          span {
            min-width: 1rem !important;
          }
        }
      }
    }
  }
}
</style>